import { selectRoomName, updateRoom } from '../reducers/roomReducer.ts';
import { useAppDispatch } from '../reducers/store.ts';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useQuizApi } from './useQuizApi.ts';

export const useRoomWebsocket = () => {

    const dispatch = useAppDispatch();
    const BASE_URL = import.meta.env.VITE_QUIZ_API_URL;
    const roomName = useSelector(selectRoomName);
    const { getRoom } = useQuizApi();
    const [eventSource, setEventSource] = useState<EventSource | undefined>(undefined);

    function connectSSE() {
        if (eventSource) {
            eventSource.close();
        }

        const newEventSource = new EventSource(BASE_URL + `/stream/${roomName}`);

        newEventSource.addEventListener('RoomUpdated', event => {
            console.log(event);
            dispatch(updateRoom(JSON.parse(event.data)));
        });

        newEventSource.onerror = function() {
            // Retry connection after a short delay
            setTimeout(() => {
                newEventSource.close();
                connectSSE();
            }, 1000);
        };

        setEventSource(newEventSource);
    }

    useEffect(() => {
        if (roomName) {
            getRoom(roomName);
            connectSSE();
        }
    }, [roomName]);

};

